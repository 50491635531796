<template>
  <div class="Login">
    <transition name="el-zoom-in-center">
      <div class="loginBox" v-if="!isFirst">
        <div class="logo_head">
          <img class="bgPic" src="../assets/img/headLogo_bg.png" alt />
          <img class="titlePic" src="../assets/img/headLogo_title.png" alt />
        </div>
        <div class="area_head">学校管理后台登录</div>
        <template v-if="loginType">
          <el-form
            :model="form"
            status-icon
            :rules="rulesForm"
            ref="form"
            label-width="35px"
            class="demo-ruleForm"
          >
            <el-form-item label prop="username">
              <el-input
                @input="trimLR('username')"
                placeholder="请输入账号"
                v-model="form.username"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label prop="password">
              <el-input
                @input="trimLR('password')"
                @keyup.enter.native="loginEvent('form')"
                placeholder="请输入密码"
                v-model="form.password"
                clearable
                show-password
              ></el-input>
            </el-form-item>
          </el-form>
        </template>
        <template v-else>
          <el-form
            :model="letterForm"
            status-icon
            ref="letterForm"
            label-width="35px"
            class="demo-ruleForm"
          >
            <el-form-item label prop="phone">
              <el-input
                @input="trimLR('phone', 'letterForm')"
                placeholder="请输入手机号码"
                v-model="letterForm.phone"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label prop="verify" class="ver-area">
              <el-input
                class="ver-input"
                @input="trimLR('verify', 'letterForm')"
                placeholder="请输入验证码"
                v-model="letterForm.verify"
                clearable
              ></el-input>
              <el-button type="primary" @click="getVerify">获取验证码</el-button>
            </el-form-item>
          </el-form>
        </template>
        <el-button type="primary" @click="loginEvent('form')">登录</el-button>
        <div class="use_tips">本后台仅限内部人员管理使用</div>
        <!-- <div class="shortMessage-area" v-if="isShowCode"><el-button type="text" @click="showShort">{{loginType ? '短信认证登录' : '账号密码登录'}}</el-button></div> -->
      </div>
    </transition>
    <transition name="el-zoom-in-top">
      <div class="editPassBox" v-if="isFirst">
        <div class="area_head">重新设置密码</div>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="新密码" prop="nwPassword">
            <el-input
              type="password"
              @input="trimLR('nwPassword', 'ruleForm')"
              v-model="ruleForm.nwPassword"
              show-password
              placeholder="请输入正确的密码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="再次确认" prop="confirmPassword">
            <el-input
              type="password"
              @input="trimLR('confirmPassword', 'ruleForm')"
              v-model="ruleForm.confirmPassword"
              show-password
              placeholder="请再次确认密码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
        <p class="remarks">注：首次登录或密码重置后需重新设置密码。</p>
      </div>
    </transition>
    <Verify
      @success="verifySuccess"
      :mode="'pop'"
      :captchaType="cap_type"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    />
    <div class="dec">
      © 2021-2023 广州长天云端科技有限公司版权所有&nbsp;&nbsp;&nbsp;&nbsp;
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
      >备案号：粤ICP备2021132316号</a>&nbsp;&nbsp;&nbsp;&nbsp;
      <img src="@/assets/img/police_badge.png" alt />
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010088"
        target="_blank"
      >
        粤公网安备
        44010602010088号
      </a>
      <!-- &nbsp;&nbsp;&nbsp;&nbsp;增值电信业务许可证号：赣B2-20180020 -->
      &nbsp;&nbsp;&nbsp;&nbsp;教APP备4401799号
    </div>
  </div>
</template>

<script>
import Verify from '../components/verifition/Verify'
import way from '../api/encapsulation'
import { permissionOpera } from '../api/jurisdiction'
export default {
  name: "Login",
  components: {
    Verify
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.confirmPassword !== '') {
          this.$refs.ruleForm.validateField('confirmPassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.nwPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error("不能输入特殊符号"));
      } else {
        callback();
      }
    };
    var validChinese = (rule, value, callback) => {
      callback(way.regChinese(value));
    };
    var validNumletter = (rule, value, callback) => {
      callback(way.regNumletter(value));
    };
    return {
      form: {
        username: '',
        password: '', // JFat0Zdc
        code: '',
      },
      isFirst: false, // 是否初次登录
      ruleForm: {
        nwPassword: '',
        confirmPassword: '',
      },
      letterForm: {
        phone: null,
        verify: null
      },
      rules: {
        nwPassword: [
          { validator: validatePass, trigger: 'blur' },
          { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' },
          // { validator: validateInput, trigger: ["blur", "change"] },
          { validator: validChinese, trigger: ["blur", "change"] },
          { validator: validNumletter, trigger: ["blur", "change"] }
        ],
        confirmPassword: [
          { validator: validatePass2, trigger: 'blur' },
          { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' },
          // { validator: validateInput, trigger: ["blur", "change"] },
          { validator: validChinese, trigger: ["blur", "change"] },
          { validator: validNumletter, trigger: ["blur", "change"] }
        ]
      },
      rulesForm: {
        username: [
          { validator: validateInput, trigger: ["blur", "change"] },
          { validator: validChinese, trigger: ["blur", "change"] }
        ],
        password: [
          // { validator: validateInput, trigger: ["blur", "change"] },
          { validator: validChinese, trigger: ["blur", "change"] }
        ]
      },
      loginData: '',
      cap_type: '',
      indexData: {
        id: null,
        parentId: -1,
        weight: '1',
        name: '首页',
        path: '/index',
        icon: 'index.png',
        permission: null,
        type: '0',
      },
      loginType: true,
      isShowCode: false,
    }
  },
  created() {
    this.showCodeEvent()
  },
  methods: {
    // 是否支持验证码登录 返回true为支持 false为不支持
    showCodeEvent() {
      this.$request.showCode().then(res => {
        if(res.data.code == 0) {
          this.isShowCode = res.data.data;
        }
      })
    },
    trimLR(name, form = 'form') {
      this[form][name] = way.clearSpace(this[form][name]);
    },
    // 校验特殊符号
    checkSpecificKey(str) {
      var specialKey = "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.firstLogin(this.ruleForm, this.loginData.access_token).then(res => {
            if (res.data.code == 0) {
              this.clearEvent();
              this.Success(res.data.data);
              // this.isFirst = false;
              this.getMenuData();
            }
          })
        } else {
          return false;
        }
      });
    },
    loginEvent(formName) {
      if (this.loginType && !this.form.username && !this.form.password) {
        this.Warn('用户名或密码未填');
        return;
      } else if(!this.loginType && !this.letterForm.phone && !this.letterForm.verify) {
        this.Warn('手机号码或验证码未填')
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.cap_type = 'blockPuzzle';
          this.$refs.verify.show();
        } else {
          return false;
        }
      });
    },
    verifySuccess(params) {
      this.form.code = params.captchaVerification;
      const user = way.encryption({
        data: this.form,
        key: 'pigxpigxpigxpigx',
        param: ['password']
      })
      this.$request.loginYk(user).then(res => {
        if (res.status == '200') {
          this.loginData = res.data;
          this.Success('登录成功')
          if (res.data.user_info.firstLogin) {
            this.isFirst = true;
          }
          this.sessionEvent();
        }
      })
    },
    sessionEvent() {
      sessionStorage.setItem("token", this.loginData.access_token);
      sessionStorage.setItem("refresh_token", this.loginData.refresh_token)
      sessionStorage.setItem("loginData", JSON.stringify(this.loginData));
      sessionStorage.setItem('key', '1');
      if (!this.isFirst) {
        this.getMenuData();
      }
    },
    // 获取左边导航菜单数据
    getMenuData() {
      this.$request.getMenus({}, res => {
        if (res.code == 0) {
          let data = res.data || [];
          let ind = data.findIndex(item => item.name == '首页')
          if (ind != -1)
            data[ind].path = 'index'
          else
            data.unshift(this.indexData);
          this.$store.commit('getMenu', data)
          sessionStorage.setItem('asideMenuData', JSON.stringify(data));
          let hasPerData = permissionOpera(data);
          hasPerData = Array.from(new Set(hasPerData.filter(item => item)));
          sessionStorage.setItem('hasPerData', JSON.stringify(hasPerData));
          this.$router.push('/Home');
        }
      })
    },
    clearEvent() {
      this.form.username = '';
      this.form.password = '';
      this.form.code = '';
    },
    showShort() {
      this.loginType = !this.loginType;
    },
    getVerify() {
      if(!this.letterForm.phone) {
        this.Warn('请输入手机号码');
        return;
      }
      this.Warn('该手机未绑定，无法获取验证码')
    }
  },
}
</script>

<style lang="less">
.Login {
  height: 100%;
  position: relative;
  background: url("../assets/img/loginBanner.jpg") no-repeat;
  background-size: 100% 100%;
  .loginBox,
  .editPassBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 512px;
    height: 432px;
    background: #ffffff;
    box-shadow: 26px 42px 62px 0px rgba(30, 135, 251, 0.2);
    border-radius: 30px;
    box-sizing: border-box;
    padding: 76px 66px 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .logo_head {
      position: inherit;
      left: 50%;
      top: -30px;
      transform: translateX(-50%);
      width: 282px;
      height: 63px;
      border-radius: 15px;
      .bgPic {
        width: 100%;
        height: 100%;
      }
      .titlePic {
        width: 170px;
        height: 44px;
        position: inherit;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .area_head {
      font-size: 24px;
      font-weight: 600;
      color: #454545;
    }
    .el-button {
      width: 100%;
    }
    .use_tips {
      font-size: 14px;
      color: #8c8c8c;
    }
    .shortMessage-area {
      position: inherit;
      right: 66px;
      bottom: 16px;
      // width: 100%;
      // text-align: right;
    }
    .el-form {
      width: 455px;
      box-sizing: border-box;
      padding-right: 40px;
      .el-form-item:first-of-type {
        margin-bottom: 40px;
      }
      .ver-area {
        .el-form-item__content {
          // width: 100%;
          display: flex;
          .ver-input {
            width: 600px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .remarks {
    color: #b6b6b6;
  }
  .dec {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 39px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 950px;
    img {
      width: 18px;
      height: 20px;
      margin-right: 10px;
      vertical-align: middle;
    }
    a {
      text-decoration: none;
      color: #fff;
    }
  }
}
</style>
